// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/cssWithMappingToString.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../usr/local/data/service/node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@-webkit-keyframes vtex-styleguide-9-x-vtex-spinner-rotate {\n  from {\n    transform: translate3d(0,0,0) rotate(0deg);\n  }\n  to {\n    transform: translate3d(0,0,0) rotate(360deg);\n  }\n}\n\n@keyframes vtex-styleguide-9-x-vtex-spinner-rotate {\n  from {\n    transform: translate3d(0,0,0) rotate(0deg);\n  }\n  to {\n    transform: translate3d(0,0,0) rotate(360deg);\n  }\n}\n\n.vtex-styleguide-9-x-rotate {\n  transform-origin: 50% 50%;\n  -webkit-animation: vtex-styleguide-9-x-vtex-spinner-rotate 0.5s infinite linear;\n          animation: vtex-styleguide-9-x-vtex-spinner-rotate 0.5s infinite linear;\n}\n", "",{"version":3,"sources":["webpack://./react/components/Spinner/styles.css"],"names":[],"mappings":"AAAA;EACE;IACE,0CAA0C;EAC5C;EACA;IACE,4CAA4C;EAC9C;AACF;;AAPA;EACE;IACE,0CAA0C;EAC5C;EACA;IACE,4CAA4C;EAC9C;AACF;;AAEA;EACE,yBAAyB;EACzB,+EAAmD;UAAnD,uEAAmD;AACrD","sourcesContent":["@keyframes vtex-spinner-rotate {\n  from {\n    transform: translate3d(0,0,0) rotate(0deg);\n  }\n  to {\n    transform: translate3d(0,0,0) rotate(360deg);\n  }\n}\n\n.rotate {\n  transform-origin: 50% 50%;\n  animation: vtex-spinner-rotate 0.5s infinite linear;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"rotate": "vtex-styleguide-9-x-rotate",
	"vtex-spinner-rotate": "vtex-styleguide-9-x-vtex-spinner-rotate"
};
module.exports = ___CSS_LOADER_EXPORT___;
